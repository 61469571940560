<template>
  <div class="page pp">
    <div class="content waterfall">
      <vue-waterfall-easy isRouterLink hrefKey="path" :gap="12"  srcKey="tpic" :imgsArr="articles" :imgWidth="290" :maxCols="6" ref="waterfall" @scrollReachBottom="getData">
        <div class="info" slot-scope="props">
          <div class="text">
            <div class="title">{{props.value.title}}</div>
            <div class="time">{{props.value.mtime}}</div>
          </div>
        </div>
      </vue-waterfall-easy>
      <!--      <div class="articles waterfall">-->
      <!--        <ul class="list">-->
      <!--          <router-link tag="li" class="li" :to="'/articles/'+item.id" v-for="(item,xh) in articles" :key="xh">-->
      <!--            <div class="title">{{item.title}}</div>-->
      <!--            <div class="time">{{item.mtime}}</div>-->
      <!--            <div class="ms">-->
      <!--              <div class="jl" v-html="jie(item.nr)"></div>-->
      <!--            </div>-->
      <!--            <div class="img" v-if="item.tpic"><img :src="item.tpic"></div>-->
      <!--            <div class="img" v-else><img src="@/assets/myimg/logo.png" style="opacity: .2"></div>-->
      <!--          </router-link>-->
      <!--        </ul>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
import {list} from '@/api/api'

export default {
    name: 'articles',
    components: {
        vueWaterfallEasy
    },
    data() {
        return {
            articles: [],
            page: 1,
            totalpage: 1
        }
    },
    props: {
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        this.getData()

    },
    methods: {
        getData() {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'article',
                perpage: 30,
                page: this.page,
                order: ["mtime DESC", "id DESC"]
            }
            list(data).then((data) => {
                this.articles = this.articles.concat(data.data.list)// data.data.list
                this.articles.forEach((el)=>{
                    this.$set(el,'path','/articles/'+el.id)

                })
                this.page++
                this.totalpage = Math.ceil(data.data.total / data.data.perpage)
                if (this.page > this.totalpage) {
                    this.$refs.waterfall.waterfallOver()
                }
            })

        },
        jie(html) {
            //let much
            let reg = new RegExp('<[^>]+>', 'gi');  //过滤所有的html标签，不包括内容
            if (typeof html != 'string') {  //不是字符串
                return html;
            }
            let last = html.replace(reg, '')
            if (window.innerWidth > 768) {
                //much = 60
            } else {
                //much = 20
            }

            //let lh = last.substr(0, much)
            return '<p>' + last + '</p>'
        }
    },
    computed: {}
}
</script>

